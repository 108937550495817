import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image2'

const StyledSteps = styled.section``

interface StepsProps {
  fields: {
    steps: Array<StepProps>
  }
}

const Steps: React.FC<StepsProps> = ({ fields }) => (
  <StyledSteps className="mt-5">
    <div className="container pt-5">
      <div className="row justify-content-center">
        <div className="col-10">
          {fields.steps.map((edge: any, index: number) => (
            <Step
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              index={index}
              fields={edge}
            />
          ))}
        </div>
      </div>
    </div>
  </StyledSteps>
)

const StyledStep = styled.div`
  color: ${(props) => props.theme.color.text.purple};
`

const StepNumber = styled.div`
  font-weight: ${(props) => props.theme.font.weight.l};
  position: relative;
  bottom: 5px;

  @media (min-width: 992px) {
    font-size: 100px;
    line-height: 60px;
  }

  @media (max-width: 991px) {
    font-size: 60px;
    line-height: 35px;
  }
`

const StepTitle = styled.h2`
  font-weight: ${(props) => props.theme.font.weight.s};

  @media (min-width: 992px) {
    font-size: ${(props) => props.theme.font.size.xl};
  }

  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size.l};
  }
`

const StepImage = styled(Image)`
  @media (min-width: 576px) {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
  }

  @media (max-width: 575px) {
    max-width: 150px;
    max-height: 150px;
  }
`

const StepLineTopHorizontal = styled.div<{ odd: boolean }>`
  position: absolute;
  border-top: 10px dotted ${(props) => props.theme.color.face.border};
  top: 1rem;

  ${(props) =>
    props.odd
      ? css`
          left: calc(100% - 3rem);
          width: 3rem;
        `
      : css`
          right: calc(100% - 2rem);
          width: 2rem;
        `};
`

const StepLineBottomHorizontal = styled.div<{ odd: boolean }>`
  position: absolute;
  border-top: 10px dotted ${(props) => props.theme.color.face.border};
  bottom: 0;

  ${(props) =>
    props.odd
      ? css`
          left: calc(100% - 3rem);
          width: 3rem;
        `
      : css`
          right: calc(100% - 2rem);
          width: 2rem;
        `};
`

const StepLineBottomVertical = styled.div<{ odd: boolean }>`
  position: absolute;
  border-right: 10px dotted ${(props) => props.theme.color.face.border};
  top: 102%;

  ${(props) =>
    props.odd
      ? css`
          left: calc(100%);
        `
      : css`
          right: calc(100%);
        `};

  @media (min-width: 768px) {
    height: 6rem;
  }

  @media (max-width: 767px) {
    height: 1rem;
  }
`

interface StepProps {
  index: number
  fields: {
    title: string
    description: string
    image: any
  }
}

const Step: React.FC<StepProps> = ({ index, fields }) => {
  const odd = index % 2 === 0
  const sort = odd ? 1 : 3

  return (
    <StyledStep className="row pb-md-5 mb-5">
      <div
        className={`col-sm-7 col-md-6 mt-4 mt-sm-0 p${
          odd ? 'r' : 'l'
        }-sm-5 order-sm-${sort} order-2 position-relative`}
      >
        {index !== 0 && (
          <StepLineTopHorizontal className="d-md-block d-none" odd={odd} />
        )}
        <div className="d-flex align-items-start">
          <StepNumber className="pr-3">{index + 1}</StepNumber>
          <StepTitle>{fields.title}</StepTitle>
        </div>
        <ParseContent className="mt-4" content={fields.description} />
        <StepLineBottomHorizontal className="d-sm-block d-none" odd={odd} />
        <StepLineBottomVertical className="d-sm-block d-none" odd={odd} />
      </div>
      <div
        className={`col-sm-5 col-md-6 d-flex justify-content-center p${
          odd ? 'l' : 'r'
        }-sm-5 order-sm-2 order-1`}
      >
        <StepImage image={fields.image} alt={fields.title} />
      </div>
    </StyledStep>
  )
}

export default Steps
